import * as Sentry from '@sentry/nextjs';

import { getBuilderJsonSSR } from './getBuilderJsonSSR';
import { isBuilderEditing } from './isBuilderEditing';
import { isBuilderPreview } from './isBuilderPreview';

export const getBuilderContent = async (modelName, urlPath, ctx) => {
  try {
    const isPreview = isBuilderPreview(ctx.req);
    const isEditing = isBuilderEditing(ctx.query);

    const { builderJson, builderStateData } = await getBuilderJsonSSR({
      modelName,
      urlPath,
      ctx,
    });

    if (builderJson?.data?.blocks || isEditing) {
      return {
        page: builderJson,
        builderStateData,
        modelName,
        urlPath,
        isEditing,
        shouldUseBuilderHomePage: true,
        isPreview,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};
