import React, { useMemo } from 'react';

import config from 'config';
import Head from 'next/head';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { Script } from '@techstyle/react-marketing';

import generateCdnImageUrl from '../../utils/generateCdnImageUrl';
import { replaceDmgHtml } from '../../utils/helpers';

const FTV_QUERY = '?fromFtv=1';
const templateValues = config.get('public.dmg.templateValues');
const htmlRegex = /(\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/g;

const Wrapper = styled.div`
  & img.lazyload,
  & img.lazyloading {
    opacity: 0;
    transition: opacity 300ms;
  }

  & img.lazyloaded {
    opacity: 1;
  }
`;

// eslint-disable-next-line react/display-name
const MemoedWrapper = React.memo(
  Wrapper,
  (prevProps, nextProps) =>
    prevProps.dangerouslySetInnerHTML.__html ===
    nextProps.dangerouslySetInnerHTML.__html
);

MemoedWrapper.displayName = 'MemoedWrapper';

const getPromoPrice = promos => {
  if (Array.isArray(promos) && promos.length > 0) {
    return promos[0].promo_price;
  }

  return promos.promo_price;
};

export default function DmsTemplate({ dms, fromFTV, media, product }) {
  const { price: membershipPrice } = useMembership();
  const { QUIZ_URL } = templateValues;

  const quizUrl = useMemo(() => {
    return `${QUIZ_URL}}${fromFTV ? FTV_QUERY : ''}`;
  }, [QUIZ_URL, fromFTV]);

  const extraTemplateValues = useMemo(() => {
    let extraTemplateValues = {};
    if (product) {
      const {
        master_product_id: productId,
        label: productLabel,
        promos,
        default_unit_price: defaultUnitPrice,
      } = product;

      const queryConnector = fromFTV ? '&' : '?';

      extraTemplateValues = {
        QUIZ_URL: `${quizUrl + queryConnector}mpid=${productId}`,
        PRODUCT_IMAGE_LABEL: productLabel,
        PRODUCT_IMAGE_SRC: generateCdnImageUrl({
          product,
          modelIndex: 0,
          angle: 1,
          imageSize: 8,
        }),
        PRODUCT_IMAGE_SRC_LAYDOWN: generateCdnImageUrl({
          product,
          modelIndex: 0,
          angle: 'laydown',
          imageSize: 8,
        }),
        // If there are no promos or promo is 0, return the VIP price instead.
        PRODUCT_PROMO_PRICE: getPromoPrice(promos) || defaultUnitPrice,
      };
    }

    // We might not have a product prop being passed, so we just set defaults for UK pricing here
    return {
      ...extraTemplateValues,
      MEMBERSHIP_PROMO_PRICE: membershipPrice,
    };
  }, [fromFTV, membershipPrice, product, quizUrl]);

  const dmsTemplateValues = useMemo(() => {
    return {
      ...templateValues,
      ...extraTemplateValues,
      dmSiteCode: dms?.dmSiteId,
    };
  }, [dms.dmSiteId, extraTemplateValues]);

  const htmlContent = useMemo(() => {
    return replaceDmgHtml(dms?.htmlCached, dmsTemplateValues);
  }, [dms, dmsTemplateValues]);

  // This ugly block of code will take a string of HTML meta tags like:
  // '<meta name="title" content="Savage X"> <meta name="description" content="Lingerie">'
  // and turn it into an object with `title` and `description` as properties. This allows us to
  // insert custom meta tags for DMS. This is not possible with `dangerouslySetInnerHTML` or
  // `document.createElement` due to limitations of SSR, and we want to be able to set a
  // `key` to overwrite existing values (i.e. like what's in DefaultHead.js).
  const metaTags = useMemo(() => {
    if (dms?.headTag) {
      return dms.headTag
        .split(/[<>]/) // remove HTML open/close tags
        .filter(content => content.startsWith('meta')) // get only `<meta />` elements
        .reduce((obj, metaTag) => {
          const props = metaTag
            .replace(/"/g, '') // remove double quotes
            .match(htmlRegex) // get HTML props
            .reduce((obj, prop) => {
              // turn all props into { key: val } pairs
              const [key, val] = prop.split('=');
              return { ...obj, [key]: val || true };
            }, {});
          if (!props.name) {
            // if it doesn't have a `name` prop, we don't want it
            return obj;
          }
          return {
            ...obj,
            [props.name]: props,
          };
        }, {});
    }

    return {};
  }, [dms]);

  if (!dms) {
    return null;
  }

  // `dangerouslySetInnerHTML` is one case where it is safe to
  // tell React to ignore the hydration mismatch warnings
  // TODO: Are these scripts injected properly during client-side navigation?
  return (
    <>
      <Head>
        {metaTags.description ? (
          <meta {...metaTags.description} key="metadescription" />
        ) : null}
        {dms.title && <title>{dms.title}</title>}
        {dms.cssFile && (
          <link
            rel="stylesheet"
            type="text/css"
            media={media || 'all'}
            href={`https://cdn.savagex.com/dm/css/${dms.cssFile}`}
          />
        )}
      </Head>

      <MemoedWrapper
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        suppressHydrationWarning
      />

      <Script
        src="https://code.jquery.com/jquery-2.1.4.min.js"
        globalScriptKey="dms-jquery"
        skipServerRender
        strategy="afterInteractive"
      />
      <Script
        src="//cdn.jsdelivr.net/jquery.slick/1.6.0/slick.min.js"
        globalScriptKey="dms-jquery-slick"
        skipServerRender
        strategy="afterInteractive"
      />
      {dms.jsFile ? (
        <Script
          src={`https://cdn.savagex.com/dm/js/${dms.jsFile}`}
          skipServerRender
          strategy="afterInteractive"
        />
      ) : null}
    </>
  );
}

DmsTemplate.propTypes = {
  dms: PropTypes.shape({
    cssFile: PropTypes.string,
    dmSiteId: PropTypes.number,
    headTag: PropTypes.string,
    htmlCached: PropTypes.string,
    jsFile: PropTypes.string,
    title: PropTypes.string,
  }),
  fromFTV: PropTypes.bool,
  media: PropTypes.string,
  name: PropTypes.string,
  product: PropTypes.object,
};

DmsTemplate.defaultProps = {
  media: '',
};
