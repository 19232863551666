import { useSession } from '@techstyle/redux-core';

import { getPathName } from './getHomePageContent';
import { useBuilderPageContent } from './useBuilderPageContent';

export function useHomePageContent({ page, dmgInfo }) {
  let modelName = 'page';
  let urlPath = '/';
  const session = useSession();

  if (dmgInfo?.payload?.redirectUrl && !session?.isLoggedIn) {
    modelName = 'landing-page';
    urlPath = getPathName(dmgInfo?.payload?.redirectUrl);
  }

  const { pageContent: defaultContent, isLoading: isLoadingDefault } =
    useBuilderPageContent({
      page,
      modelName: 'page',
      urlPath: '/',
    });

  const { pageContent: landingContent, isLoading: isLoadingLanding } =
    useBuilderPageContent({
      page,
      modelName,
      urlPath,
    });

  return {
    pageContent:
      landingContent && !isLoadingLanding ? landingContent : defaultContent,
    isLoading: isLoadingDefault || isLoadingLanding,
  };
}
