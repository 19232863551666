import React, { useCallback, useEffect, useState } from 'react';

import '@builder.io/widgets';
import * as Sentry from '@sentry/nextjs';
import config from 'config';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { withTheme } from 'styled-components';

import { useMembership } from '@techstyle/react-accounts';
import { desktop } from '@techstyle/react-components';
import { loadSession, getSession, useDomain } from '@techstyle/redux-core';

import createPage from '../registry';
import { loadDmg } from '../src/actions/dmg';
import { loadDms } from '../src/actions/dms';
import BuilderComponent from '../src/components/BuilderComponent';
import DmsTemplate from '../src/components/DmsTemplate';
import { Component as Loading } from '../src/components/Loading';
import { Container as MainTemplate } from '../src/components/MainTemplate';
import { getHomePageContent } from '../src/utils/getHomePageContent';
import { useLDFlags } from '../src/utils/LD/useLDFlags';
import loadCustomEvent from '../src/utils/loadCustomEvent';
import {
  nodeComponents,
  registerBuilderComponents,
  registerBuilderNodesMenu,
} from '../src/utils/registerBuilderComponents';
import { getPathname } from '../src/utils/url';
import { useHomePageContent } from '../src/utils/useHomePageContent';

const firstTimeVisitorDmgCodes = config.get('public.dmgCodes.firstTimeVisitor');
const returningVisitorDmgCodes = config.get('public.dmgCodes.returningVisitor');

const StyledMainTemplate = styled(MainTemplate)`
  & img.lazyload,
  & img.lazyloading {
    opacity: 0;
    transition: opacity 300ms;
  }

  & img.lazyloaded {
    opacity: 1;
  }
`;

const BuilderStyledMainTemplate = styled(MainTemplate)`
  background-image: url(${({ mobileBackground }) => mobileBackground});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;

  ${desktop`
  background-image: url(${({ desktopBackground }) => desktopBackground});
  `}
  .builder-accordion-title-open svg.chevron-icon {
    transform: rotate(180deg);
    transition: 0.3s;
  }
`;

const loadVisitorSite = async (dmgCode, dispatch) => {
  const getConsoleDmg = async () => {
    try {
      const result = await dispatch(loadDmg(dmgCode));
      return result.payload;
    } catch (err) {
      Sentry.captureException(err);
      return { errorMessage: err };
    }
  };

  try {
    const dmgResponse = await getConsoleDmg();

    if (dmgResponse?.errorMessage) {
      throw new Error(dmgResponse.errorMessage);
    }

    /* Success! */
    return {
      dmg: dmgResponse,
      dmgCode,
      dms: null,
    };
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

function LoadingHomePage() {
  return (
    <MainTemplate hideRegistrationModal>
      <Loading />
    </MainTemplate>
  );
}

const DMSContent = ({ dms, dmg, dmgCode }) => {
  const { tld } = useDomain();

  return dms ? (
    <DmsTemplate dms={dms} />
  ) : (
    <DmsTemplate
      dms={dmg}
      fromFTV={firstTimeVisitorDmgCodes[tld] === dmgCode}
    />
  );
};

DMSContent.propTypes = {
  dms: PropTypes.object,
  dmg: PropTypes.object,
  dmgCode: PropTypes.string,
};

const EMPTY_OBJECT = {};

function HomePage({
  dmg,
  dmgCode,
  dms,
  query = EMPTY_OBJECT,
  session = EMPTY_OBJECT,
}) {
  const dispatch = useDispatch();
  const { isServerOnly } = session;

  useEffect(() => {
    const loadDmgSession = async () => {
      let headers = {};
      if (query.ip) {
        headers = { 'true-client-ip': query.ip };
      }

      if (dmgCode && dmg) {
        const searchParams = {};
        if (dmg.dmSiteId) {
          searchParams.dmSiteId = dmg.dmSiteId;
          searchParams.dmGatewayId = dmg.dmGatewayId;
          searchParams.dmGatewayTestSiteId = dmg.dmGatewayTestSiteId;
        }
        // TODO: we `loadSession` twice because otherwise we have a race condition with
        // our page's initial `loadSession` call. The first call to `loadSession` here
        // guarantees that the second call will go through. We can remove the first
        // once this race condition is resolved in redux-core.
        await dispatch(loadSession());
        loadCustomEvent('sxfReady');
        await dispatch(
          loadSession({
            dmgCode,
            headers, // FIXME: Don't think this does anything in redux-core.
            searchParams,
          })
        );
      }
    };

    loadDmgSession();
  }, [dispatch, dmg, dmgCode, query.ip]);

  if (isServerOnly) {
    return <LoadingHomePage />;
  }

  return (
    <StyledMainTemplate>
      {dmg ? <DMSContent dmg={dmg} dmgCode={dmgCode} dms={dms} /> : null}
    </StyledMainTemplate>
  );
}

HomePage.propTypes = {
  /** The direct marketing site (dms) is not a responsive site.
   * This prop has the code to load the desktop and mobile version of the dms.
   */
  dmg: PropTypes.any,
  /** The mobile and desktop direct marketing gateway (dmg) codes for the current domain. */
  dmgCode: PropTypes.string,
  /** Use the `?dms_site=12345` query param to force load the direct marketing site (dms). */
  dms: PropTypes.any,
  session: PropTypes.object.isRequired,
  query: PropTypes.object,
  tld: PropTypes.string,
};

function HomePageWrapper(props) {
  const { page, modelName, builderStateData, isEditing, isPreview, dmgInfo } =
    props;

  const { pageContent, isLoading } = useHomePageContent({
    page,
    dmgInfo,
  });

  const { 'homepage-aa-test': isHomePageAATestEnabled } = useLDFlags();
  const { customerId } = useMembership();
  const { hideSkinnyBanner, desktopBackground, mobileBackground } =
    pageContent?.data || {};
  const [contentData, setContentData] = useState(pageContent?.data || {});
  const isLive = !isEditing && !isPreview;

  const handleContentLoaded = useCallback(
    pageContentData => {
      // on preview, we want to update both the model fields (e.g. backgrounds)
      // once the content is loaded, since it's never loaded on the server side
      if (isLive || !pageContentData) {
        return;
      }
      setContentData(pageContentData || {});
    },
    [isLive, setContentData]
  );

  // In the Builder editor the page could be empty
  // also show if we're fetching content from builder. If it fails (or there's no content),

  if ((!isLoading && pageContent?.data?.blocks) || !isLive) {
    return (
      <BuilderStyledMainTemplate
        hideSkinnyBanner={
          isLive ? hideSkinnyBanner : contentData.hideSkinnyBanner
        }
        desktopBackground={
          isLive ? desktopBackground : contentData.desktopBackground
        }
        mobileBackground={
          isLive ? mobileBackground : contentData.mobileBackground
        }
      >
        <div data-tag={`HomePageAATest-${isHomePageAATestEnabled}`} />
        <BuilderComponent
          model={modelName}
          content={pageContent}
          data={builderStateData}
          contentLoaded={handleContentLoaded}
        />
      </BuilderStyledMainTemplate>
    );
  }

  if ((!customerId && !isLoading) || !isLoading) {
    return <HomePage {...props} />;
  }

  return <LoadingHomePage />;
}

HomePageWrapper.propTypes = {
  session: PropTypes.object.isRequired,
  page: PropTypes.object,
  modelName: PropTypes.string,
  urlPath: PropTypes.string,
  builderStateData: PropTypes.object,
  isEditing: PropTypes.bool,
  isPreview: PropTypes.bool,
};

HomePageWrapper.getInitialProps = async ctx => {
  const { store, query, asPath } = ctx;
  const pathname = getPathname(asPath);

  await store.dispatch(loadSession());

  const state = store.getState();
  const { tld } = state.domain;
  const session = getSession(state);
  const { isLoggedIn } = session;

  const homePageContent = await getHomePageContent(ctx, isLoggedIn);

  if (homePageContent) {
    return homePageContent;
  }

  const dmsQuery = parseInt(query.dm_site, 10);
  const defaultProps = {
    dmg: null,
    dmgCode: null,
    dms: null,
    query,
    tld,
    modelName: 'page',
    urlPath: pathname,
  };

  if (dmsQuery) {
    let dms;
    let error;
    try {
      dms = await store.dispatch(loadDms(dmsQuery));
    } catch (err) {
      error = err;
    }

    return {
      ...defaultProps,
      dms: error ? null : dms.payload,
    };
  }

  let dmgCode;

  const currentDmgCode = session.dmGatewayCode;
  if (session.isReturningVisitor) {
    dmgCode = currentDmgCode || returningVisitorDmgCodes[tld];

    const result = await loadVisitorSite(dmgCode, store.dispatch);
    if (result) {
      return { ...defaultProps, ...result };
    }
    // If you got here, then there was an error loading the dms for returning visitors.
    // Intentionally 'fallthrough' to try loading the dms for first time visitors.
  }

  if (session.isReturningVisitor || session.isFirstTimeVisitor) {
    dmgCode = currentDmgCode || firstTimeVisitorDmgCodes[tld];

    const result = await loadVisitorSite(dmgCode, store.dispatch);

    if (result) {
      return {
        ...defaultProps,
        ...result,
        session,
      };
    }
    // There was an error loading the dms for first time visitors.
    // Intentionally 'fallthrough' to load the default page.
  }

  // Render default page
  return defaultProps;
};

registerBuilderComponents(nodeComponents);
registerBuilderNodesMenu();

export default createPage(withTheme(HomePageWrapper), {
  resourceBundles: ['site_pdp'],
  marketingInfo: {
    segmentCategory: 'homepage',
  },
  mapStateToProps: state => ({
    session: getSession(state),
  }),
  mapDispatchToProps: {},
});
