import { loadDmg } from '../../src/actions/dmg';

import { getBuilderContent } from './getBuilderContent';

export const getPathName = (path: string | URL) => {
  const placeHolderBaseUrl = 'https://www.example.com';
  const url = new URL(path, placeHolderBaseUrl);
  return url?.pathname;
};

export async function getHomePageContent(ctx, isLoggedIn) {
  const { query, store } = ctx;
  const defaultContentPromise = getBuilderContent('page', '/', ctx);
  let dmgInfo;
  if (query?.dmg) {
    try {
      dmgInfo = await store.dispatch(loadDmg(query?.dmg));
    } catch (e) {
      // dmgCode not found, do nothing
    }
  }

  if (dmgInfo?.payload?.redirectUrl && !isLoggedIn) {
    const builderUrl = getPathName(dmgInfo?.payload?.redirectUrl);

    const dmgCodeContent = await getBuilderContent(
      'landing-page',
      builderUrl,
      ctx
    );

    if (dmgCodeContent) {
      return { ...dmgCodeContent, dmgInfo };
    }
  }

  return await defaultContentPromise;
}
