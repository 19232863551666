export const LOAD_DMS_REQUEST = 'LOAD_DMS_REQUEST';
export const LOAD_DMS_SUCCESS = 'LOAD_DMS_SUCCESS';
export const LOAD_DMS_FAILURE = 'LOAD_DMS_FAILURE';

export function loadDms(id) {
  return {
    bentoApi: {
      endpoint: `marketing/dms/${id}`,
      actions: [LOAD_DMS_REQUEST, LOAD_DMS_SUCCESS, LOAD_DMS_FAILURE],
    },
  };
}
