export const LOAD_DMG_REQUEST = 'LOAD_DMG_REQUEST';
export const LOAD_DMG_SUCCESS = 'LOAD_DMG_SUCCESS';
export const LOAD_DMG_FAILURE = 'LOAD_DMG_FAILURE';

export function loadDmg(id) {
  return {
    bentoApi: {
      endpoint: `marketing/dmg/${id}`,
      actions: [LOAD_DMG_REQUEST, LOAD_DMG_SUCCESS, LOAD_DMG_FAILURE],
    },
  };
}
